import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";
import { formUrlEncodedParameters } from '../lib/form_parameters_converter'

export default class extends Controller {
  static targets = ['globalSearchInput']
  static values = { url: String, fromFrame: Boolean }
  static classes = ['open']

  open() {
    this.globalSearchInputTarget.classList.add(this.openClass)
    this.globalSearchInputTarget.focus()
  }

  close() {
    this.globalSearchInputTarget.classList.remove(this.openClass)
    this.globalSearchInputTarget.blur()
  }

  connect() {
    this.initialState = true
  }

  search(event) {
    let urlEncodedParams = ''
    let needToQuery = false
    if (event.currentTarget.value.length < 3) {
      if (this.initialState === false) {
        urlEncodedParams = ''
        needToQuery = true
        this.initialState = true
      }
    } else {
      urlEncodedParams = formUrlEncodedParameters(event.currentTarget.form)
      needToQuery = true
      this.initialState = false
    }
    if (needToQuery === true || this.fromFrameValue === true) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout((urlEncodedParams) => {
        if (this.fromFrameValue) {
          this.element.requestSubmit()
        } else {
          get(`${this.urlValue}${urlEncodedParams}`)
        }
      }, 300, urlEncodedParams )
    }
  }
}
